<template>
	<div style="margin: auto;text-align: center;">
		<p style="margin: auto; font-size: 42px;text-align: center;">你专属的召唤器</p>
		<div style="margin-top: 40px;">召唤次数 {{num}}</div>
		<el-input v-model="inputValue" style="width: 200px;margin-top: 40px;" placeholder="请输入内容"></el-input>
		<el-button type="primary" @click="onSubmit('1')" style="margin-left: 20px;" round>发送</el-button>
		<div style="margin-top: 40px;">
		<el-button  class="button_style" type="primary" @click="onSubmit('我爱你')" round>我爱你</el-button>
		<el-button  class="button_style" type="primary" @click="onSubmit('想你啦')" round>想你啦</el-button>
		<el-button  class="button_style" type="primary" @click="onSubmit('在干嘛')" round>在干嘛</el-button>
		<el-button  class="button_style" type="primary" @click="onSubmit('调戏一下')" round>调戏一下</el-button>
		<el-button  class="button_style" type="primary" @click="onSubmit('摸摸头')" round>摸摸头</el-button></div>
	</div>
</template>
<script src="https://cdn.staticfile.org/vue/2.4.2/vue.min.js"></script>
<script src="https://cdn.staticfile.org/axios/0.18.0/axios.min.js"></script>
<script>
	import Vue from 'vue'
	import axios from 'axios'; //引入axios
	import VueAxios from 'vue-axios'; // 引入vue-axios
	Vue.use(VueAxios, axios); //使用
	export default {
		name: 'HelloWorld',
		data() {
			return {
				// 版本号
				inputValue: '宝宝在召唤',
				num: 0
			}
		},
		mounted() {
			this.num = 0
		},
		methods: {
			onSubmit(e) {
				if (e === '1') {
					var url =
						'https://api2.pushdeer.com/message/push?pushkey=PDU716TNIfc6hz0BjoSw6oORTympX6MzOsrDTzO&text=' +
						this.inputValue

				} else {
					var url =
						'https://api2.pushdeer.com/message/push?pushkey=PDU716TNIfc6hz0BjoSw6oORTympX6MzOsrDTzO&text=' + e

				}
				Vue.axios.get(url)
				this.num++
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button_style{
	margin-top: 20px;
}
</style>
